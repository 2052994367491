import React from 'react'
import { initializeApp } from 'firebase/app';
import { getFirestore ,collection, query, onSnapshot, orderBy, limit, getDocs } from "firebase/firestore";

initializeApp ({
        apiKey: "AIzaSyDf39bBLLxrYHFvGGg0NZcXwDg1v6sL2EA",
        authDomain: "tiny-weather-76b00.firebaseapp.com",
        projectId: "tiny-weather-76b00",
        storageBucket: "tiny-weather-76b00.appspot.com",
        messagingSenderId: "922414469665",
        appId: "1:922414469665:web:bb37d345de64c0eb9e0960",
        measurementId: "G-WDWP6F8S5D"
    });

var firestore = getFirestore();

const getChartData = (data, labels, name) => {
		const chartData = {
					labels: labels,
					datasets: [{
                        label: name,
                        data: data,
                        fill: false,
                        // borderColor: 'rgb(75, 192, 192)',
                        tension: 0.3
					}],
					
				};
		return chartData;
	}

const stationId = "bVzmP2gEVOE2cUMDpLWw";

class Test extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tempChartData: null,
            pressureChartData: null,
            humidityChartData: null,
            windSpeedChartData: null,
            windDirectionChartData: null,
            batterVoltageChartData: null,
            latestDatum: null,
            error: null,
        }
    }
    
    componentDidMount() {
        let data = [];
        const q = query(collection(firestore, `Stations/${stationId}/Data`), orderBy("dateStamp", "desc"), limit(120));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            
            querySnapshot.docChanges().forEach((changes) => {
            if (changes.type === "added") {
                data = [...data,
                    {date: changes.doc.data().dateStamp.seconds,
                    id: changes.doc.id,
                    temperature: changes.doc.data().temperature,
                    batteryVoltage: changes.doc.data().batteryVoltage,
                    windDirection: changes.doc.data().windDirection,
                    windSpeed: changes.doc.data().windSpeed,
                    pressure: changes.doc.data().pressure,
                    humidity: changes.doc.data().humidity,
                }]
            }
            if (changes.type === "removed") {
                data = [...data.filter(x => x.id !== changes.doc.id)];
            }
            console.log("data", data);
            const orderedData = data.sort((a, b) => a.date - b.date);
            console.log("orderedData", orderedData);
            const latestDatum = orderedData[orderedData.length -1 ];

            // const labels = orderedData.map(x => new Date(x.date * 1000));
            const labels = orderedData.map(x => x.date * 1000);

            const tempList = orderedData.map(x => x.temperature);
            const pressureList = orderedData.map(x => x.pressure);
            const humidityList = orderedData.map(x => x.humidity);
            const windSpeedList = orderedData.map(x => x.windSpeed);
            const windDirectionList = orderedData.map(x => x.windDirection);
            const batterVoltageList = orderedData.map(x => x.batteryVoltage);

            const tempChartData = getChartData(tempList, labels, 'Temperature');
            const pressureChartData = getChartData(pressureList, labels, 'Pressure');
            const humidityChartData = getChartData(humidityList, labels, 'Humidity');
            const windSpeedChartData = getChartData(windSpeedList, labels, 'Wind Speed');
            const windDirectionChartData = getChartData(windDirectionList, labels, 'Wind Direction');
            const batterVoltageChartData = getChartData(batterVoltageList, labels, 'Battery Voltage');

                this.setState({
                            tempChartData,
                            pressureChartData,
                            humidityChartData,
                            windSpeedChartData,
                            windDirectionChartData,
                            batterVoltageChartData,
                            latestDatum,
                });
            });
        },
        (error) => {
            this.setState({
                error: JSON.stringify(error)
            })
        });
    }

    
    

    render() {
        return this.state.latestDatum ? <p>{`Last updated: ${new Date(this.state.latestDatum.date)}`}</p> : <p>Loading....waiting then check console</p> 
    }

}



export default Test;